import "./App.css";
import React, { useEffect, useState } from "react";

const App: React.FC = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);


    // Function to handle scrolling
    const scrollToNextVideo = () => {
        const sections = [
            document.querySelector(".video-bg"),
            document.querySelector(".video-bg-second"),
            document.querySelector(".video-bg-third"),
        ];

        const currentScroll = window.scrollY;
        const viewportHeight = window.innerHeight;

        // Determine the next section to scroll to
        let nextSectionIndex = 0;

        if (currentScroll < viewportHeight) {
            nextSectionIndex = 1;
        } else if (currentScroll < 2 * viewportHeight) {
            nextSectionIndex = 2;
        }

        const nextSection = sections[nextSectionIndex];

        if (nextSection) {
            const targetPosition = nextSectionIndex * viewportHeight;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        // Check if the screen width is less than 768px
        const handleResize = () => setIsMobile(window.innerWidth < 768);

        // Initial check and add event listener
        handleResize();
        window.addEventListener("resize", handleResize);

        // Cleanup listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const cursorRing = document.querySelector(".cursor-ring");

        // Check if the cursor ring exists before trying to set its position
        if (cursorRing) {
            document.addEventListener("mousemove", (e) => {
                if (cursorRing instanceof HTMLElement) {
                    const scrollY = window.scrollY;
                    const scrollX = window.scrollX;

                    cursorRing.style.top = `${e.clientY + scrollY}px`;
                    cursorRing.style.left = `${e.clientX + scrollX}px`;
                }
            });
        }

        return () => {
            document.removeEventListener("mousemove", () => { });
        };
    }, []);

    return (
        <div>
            <div className="cursor-ring"></div>

            {/* First Section */}
            {isMobile ? (
                <img
                    className="video-bg pic-bg"
                    src="pic_01.webp"
                    alt="Mobile Background 1"
                />
            ) : (
                <video
                    className="video-bg"
                    src="https://videos.pexels.com/video-files/8940717/8940717-uhd_3840_2160_25fps.mp4"
                    autoPlay
                    loop
                    muted
                ></video>
            )}

            {/* Overlay */}
            <div className="overlay"></div>

            {/* Hero Section */}
            <section className="hero-title">
                <h1>BEAUMONT FC ACADEMY</h1>
            </section>
            <section className="hero">
                <h1>Developing the Next Generation of Soccer Stars</h1>
                <button onClick={scrollToNextVideo}>See More</button>
            </section>

            {/* Second Section */}
            {isMobile ? (
                <img
                    className="video-bg-second pic-bg"
                    src="pic_02.webp"
                    alt="Mobile Background 2"
                />
            ) : (
                <video
                    className="video-bg-second"
                    src="https://videos.pexels.com/video-files/6078638/6078638-uhd_3840_2160_25fps.mp4"
                    autoPlay
                    loop
                    muted
                ></video>
            )}


            <section className="hero section-two">
                <h1>Three Coaches</h1>
                <h1>Over 60 Years of</h1>
                <h1>Accumulated Experience</h1>
            </section>
            <section className="hero">
                <button onClick={scrollToNextVideo}>See More</button>
            </section>

            {/* Third Section */}
            {isMobile ? (
                <img
                    className="video-bg-third pic-bg"
                    src="pic_03.webp"
                    alt="Mobile Background 3"
                />
            ) : (
                <video
                    className="video-bg-third"
                    src="https://videos.pexels.com/video-files/3192198/3192198-uhd_3840_2160_25fps.mp4"
                    autoPlay
                    loop
                    muted
                ></video>
            )}


            <section className="hero section-three">
                <h1>Local to the</h1>
                <h1>Inland Empire</h1>
                <button style={{ padding: 0 }} onClick={() => setIsContactModalOpen(true)}>
                    <p>Contact Us</p>
                </button>
            </section>

            {/* Contact Modal */}
            {isContactModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Contact Us</h2>
                        <p>
                            <strong>Coach Mario: </strong>
                            <a href='tel:+1951-236-1315'>+1 951-236-1315</a>
                        </p>
                        <p>
                            <strong>Coach Oscar: </strong>
                            <a href='tel:+1951-692-0092'>+1 951-692-0092</a>
                        </p>
                        <p>
                            <strong>Coach Drew: </strong>
                            <a href='tel:+1909-358-5018'>+1 909-358-5018</a>
                        </p>
                        <button style={{ marginTop: '10px' }} onClick={() => setIsContactModalOpen(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;
